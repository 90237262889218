import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { CustomComponentBase } from '@shared/common/custom-component-base';
import { PullPlanTaskDto } from '@shared/service-proxies/service-proxies';
import { ITaskMouseEvent, TaskMouseEvent } from '../tasknote/tasknote.component';

@Component({
    selector: 'baseline-tasknote',
    templateUrl: './baseline-tasknote.component.html',
    styleUrls: ['./baseline-tasknote.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BaselineTaskNoteComponent extends CustomComponentBase implements OnInit { //OnChanges

    @Input() baselineTask: PullPlanTaskDto;
    @Input() hide: boolean = false;
    @Input() scale: number = 1;
    @Input() highlight: boolean = false;

    @Output() MouseEvent = new EventEmitter<ITaskMouseEvent>();

    get strikethrough(): boolean {
        return (this.baselineTask?.taskStatusId == 3);
    }

    get atRiskOrBlocked(): boolean {
        return (this.baselineTask?.taskStatusId == 2);
    }

    customStyles: string = ''; //this will be used to hold the positioning styles in the whiteboard
    swimlaneTeamColour: string = '';
    swimlaneTeamTextColour: string = '';

    constructor(
        private injector: Injector,
    ) {
        super(injector);
    }

    ngOnInit(): void {
    }
    
    // ngOnChanges(changes: SimpleChanges): void {
    //     if (!changes) { return; }
    // }

    public setCustomStyles(styles: string): void {
        this.customStyles = styles;
        this.swimlaneTeamColour = this.baselineTask.swimlaneTeamColour;
        this.swimlaneTeamTextColour = this.baselineTask.swimlaneTeamTextColour;
    }

    onMouseEvents(type: string, event: DragEvent): void {
        this.MouseEvent.emit(new TaskMouseEvent(type, this.baselineTask));
    }
}
